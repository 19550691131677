import React, { useState, useEffect, useRef } from "react";
import heroImg from "../components/assets/images/hero-alt.png";
import heroImgback from "../components/assets/images/hero-shape-purple.png";
import { About } from "./About";
import { WhyUs } from "./WhyUs";
import SubMenu from "../components/common/SubMenu";
import { motion, AnimatePresence } from "framer-motion";
import { db } from "../components/firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../components/common/Loader";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Modals from "../components/common/Modals";

export const Home = () => {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        const papersCollection = collection(db, "resources");
        const papersSnapshot = await getDocs(papersCollection);
        const papersData = papersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPapers(papersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching papers:", error);
        setLoading(false);
      }
    };

    fetchPapers();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // Sort papers by date (newest first)
  const sortedPapers = [...papers].sort((a, b) => {
    const dateA = new Date(a.year, new Date(a.month + " 1, 2024").getMonth());
    const dateB = new Date(b.year, new Date(b.month + " 1, 2024").getMonth());
    return dateB - dateA;
  });

  // Only take the latest five papers
  const lastFivePapers = sortedPapers.slice(0, 5);

  return (
    <>
      <Modals />
      <HomeContent />
      <LastFivePapers papers={lastFivePapers} />
      <About />
      <br />
      <br />
      <br />
      <WhyUs />
    </>
  );
};

export const HomeContent = () => {
  const [index, setIndex] = useState(0);
  const words = ["Exam Success", "Test Excellence", "Academic Achievement"];

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [words.length]);

  return (
    <section className="bg-secondary py-10 h-[92vh] md:h-full">
      <SubMenu />
      <div className="container">
        <div className="flex items-center justify-center md:flex-col">
          <div className="left w-1/2 text-black md:w-full">
            <motion.h1
              className="lg:text-7xl text-4xl leading-tight text-black font-bold"
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 60, damping: 20 }}
            >
              Welcome to <br /> &nbsp;
              <span className="text-blue-500">Ravi</span> TestPapers
            </motion.h1>
            <motion.h3
              className="lg:text-2xl text-lg mt-5 ml-3 leading-relaxed"
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 60,
                damping: 20,
                delay: 0.75,
              }}
            >
              One stop destination
              <br />
              for{" "}
              <AnimatePresence mode="wait">
                <motion.span
                  key={words[index]}
                  className="text-blue-500 font-semibold"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {words[index]}
                </motion.span>
              </AnimatePresence>
            </motion.h3>
            <div className="relative text-base font-semibold text-blue-600 focus-within:text-gray-400 ml-4 mt-12 hover:text-white ">
              <span className="absolute inset-y-0 left-0 flex items-center">
                <button className="py-2 px-5 focus:outline-none focus:shadow-outline rounded-3xl bg-white hover:bg-blue-600 border border-blue-600">
                  Explore More
                </button>
              </span>
            </div>
          </div>
          <div className="right w-1/2 md:w-full relative">
            <div className="images relative">
              <motion.img
                src={heroImgback}
                alt="Background Shape"
                className="absolute top-32 left-10 w-96 md:left-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              />
              <div className="img h-[85vh] w-full">
                <motion.img
                  src={heroImg}
                  alt="Hero"
                  className="h-full w-full object-contain z-20 relative"
                  initial={{ y: -50 }}
                  animate={{ y: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 120,
                    damping: 90,
                    mass: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LastFivePapers = ({ papers }) => {
  const sliderRef = useRef(null);
  const [sliderWidth, setSliderWidth] = useState(0);

  // Duplicate the papers array to achieve a seamless infinite scroll
  const slides = [...papers, ...papers];

  useEffect(() => {
    if (sliderRef.current) {
      // Since we've duplicated the slides, we take half of the total scrollWidth
      setSliderWidth(sliderRef.current.scrollWidth / 2);
    }
  }, [papers]);

  const getCoverImage = (item) => {
    return item.thumbnail
      ? item.thumbnail
      : "https://via.placeholder.com/450x300?text=No+Image";
  };

  return (
    <section className="last-five-papers bg-[#F3F4F8] py-8">
      <div className="w-4/5 m-auto">
        <h2 className="text-2xl font-semibold text-black mb-4">
          Latest Papers
        </h2>
        <div ref={sliderRef} className="overflow-hidden">
          <motion.div
            className="flex space-x-4 p-4"
            animate={{ x: -sliderWidth }}
            transition={{ duration: 20, ease: "linear", repeat: Infinity }}
          >
            {slides.map((item, index) => (
              <motion.div
                key={index}
                className="box rounded-lg shadow-shadow1 bg-white min-w-[450px] flex-shrink-0 p-1"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: (index % papers.length) * 0.1 }}
              >
                <div className="images rounded-t-lg relative overflow-hidden h-40 w-full">
                  <img
                    src={getCoverImage(item)}
                    alt="Paper Thumbnail"
                    className="rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 hover:scale-125 duration-300"
                  />
                  <div className="categ flex gap-4 absolute top-0 m-3">
                    <span className="text-[14px] bg-rose-500 p-1 px-3 text-white rounded-[5px] shadow-md">
                      {item.subject}
                    </span>
                    <span className="text-[14px] bg-green-500 p-1 px-3 text-white rounded-[5px] shadow-md">
                      {item.month} {item.year}
                    </span>
                  </div>
                </div>
                <div className="text p-3">
                  <h3 className="text-black my-4 font-medium h-10">
                    {item.title}
                  </h3>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 p-3">
                  {item.referenceLink && (
                    <a
                      href={item.referenceLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[14px] text-primary hover:text-primary"
                    >
                      Reference
                    </a>
                  )}
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[14px] ml-2 flex items-center hover:text-primary"
                  >
                    View Paper <HiOutlineArrowNarrowRight />
                  </a>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Home;
