import React, { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  increment,
} from "firebase/firestore";
import { db } from "../components/firebase/firebase";
import LogoImg from "../components/assets/images/logo-black.png";

const SubjectPapers = () => {
  const { type, grade, subject } = useParams();
  const [filteredPapers, setFilteredPapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const papersPerPage = 12;

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        const papersRef = collection(db, "resources");
        let q = query(papersRef, where("type", "==", type));

        // Filter by grade if not "all"
        if (grade !== "all") {
          q = query(q, where("grade", "==", grade));
        }

        const querySnapshot = await getDocs(q);
        const papersData = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setFilteredPapers(papersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching papers:", error);
        setLoading(false);
      }
    };

    fetchPapers();
  }, [type, grade, subject]);

  const getCoverImage = (paper) => {
    return paper.thumbnail ? paper.thumbnail : "";
  };

  // Increment the view count in Firestore and update local state
  const handleViewPaper = async (paper) => {
    try {
      const paperRef = doc(db, "resources", paper.id);
      await updateDoc(paperRef, {
        views: increment(1),
      });
      setFilteredPapers((prevPapers) =>
        prevPapers.map((p) =>
          p.id === paper.id ? { ...p, views: (p.views || 0) + 1 } : p
        )
      );
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  // Filter papers based on the search term (checks title and subject)
  const searchFilteredPapers = searchTerm
    ? filteredPapers.filter(
        (paper) =>
          paper.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (paper.subject &&
            paper.subject.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    : filteredPapers;

  // Sort papers in descending order based on year and month (newest first)
  const sortedPapers = searchFilteredPapers.slice().sort((a, b) => {
    const dateA = new Date(a.year, new Date(a.month + " 1, 2024").getMonth());
    const dateB = new Date(b.year, new Date(b.month + " 1, 2024").getMonth());
    return dateB - dateA;
  });

  // Pagination logic
  const indexOfLastPaper = currentPage * papersPerPage;
  const indexOfFirstPaper = indexOfLastPaper - papersPerPage;
  const currentPapers = sortedPapers.slice(indexOfFirstPaper, indexOfLastPaper);
  const totalPages = Math.ceil(sortedPapers.length / papersPerPage);

  // Render pagination buttons
  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`mx-1 px-3 py-1 border rounded ${
            currentPage === i ? "bg-blue-600 text-white" : "bg-white text-blue-600"
          }`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="courses bg-[#F3F4F8] py-16">
      <div className="w-4/5 m-auto">
        {/* Header with title and search input */}
        <div className="flex justify-between items-center mb-16">
          <div className="heading">
            <h1 className="text-3xl font-semibold text-black">
              {type.toUpperCase()} {grade !== "all" && `${grade}`}{" "}
              {subject !== "all" && subject} Practice Papers
            </h1>
            <span className="text-sm mt-2 block">
              Work on your skills and improve your knowledge with our free papers.
            </span>
          </div>
          <div className="search flex items-center">
            <input
              type="text"
              placeholder="Search papers..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1); // Reset to first page on new search
              }}
              className="p-2 border border-gray-300 rounded"
            />
            <button
              className="ml-2 p-2 bg-blue-600 text-white rounded"
              onClick={() => {}}
            >
              Search
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
          {currentPapers.map((paper, index) => (
            <motion.div
              key={paper.id}
              className="box rounded-lg shadow-shadow1 bg-white"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="images rounded-t-lg relative overflow-hidden h-40 w-full">
                <img
                  src={getCoverImage(paper) || LogoImg}
                  alt=""
                  className="rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                />
                <div className="categ flex gap-4 absolute top-0 m-3">
                  <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                    {paper.subject}
                  </span>
                  <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                    {paper.month} {paper.year}
                  </span>
                </div>
              </div>
              <div className="text p-3">
                <h3 className="text-black my-4 font-medium h-10">
                  {paper.title}
                </h3>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 p-3">
                <div className="flex flex-col">
                  {paper.referenceLink && (
                    <a
                      href={paper.referenceLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[14px] text-primary"
                    >
                      Free Answer
                    </a>
                  )}
                  <span className="text-[14px] text-gray-600 mt-1">
                    Views: {paper.views || 0}
                  </span>
                </div>
                <NavLink
                  to={`${paper.link}`}
                  onClick={() => handleViewPaper(paper)}
                  className="text-[14px] ml-2 flex items-center hover:text-primary"
                >
                  View Paper <HiOutlineArrowNarrowRight />
                </NavLink>
              </div>
            </motion.div>
          ))}
        </div>
        {/* Pagination */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-8">{renderPageNumbers()}</div>
        )}
      </div>
    </section>
  );
};

export default SubjectPapers;
