import React, { useState, useEffect } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { db } from "../components/firebase/firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  increment,
} from "firebase/firestore";
import Loader from "../components/common/Loader";
import LogoImg from "../components/assets/images/logo-black.png";
import Modals from "../components/common/Modals";

export const Practice = () => {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        const papersCollection = collection(db, "resources");
        const papersSnapshot = await getDocs(papersCollection);
        const papersData = papersSnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setPapers(papersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching papers:", error);
        setLoading(false);
      }
    };

    fetchPapers();
  }, []);

  const getCoverImage = (item) => {
    if (item.thumbnail) {
      return item.thumbnail; // Use the thumbnail if it exists
    } else {
      return ""; // Return an empty string (or a placeholder) if not available
    }
  };

  // Increment the view count in Firestore and update local state
  const handleViewPaper = async (paper) => {
    try {
      const paperRef = doc(db, "resources", paper.id);
      await updateDoc(paperRef, {
        views: increment(1),
      });
      setPapers((prevPapers) =>
        prevPapers.map((p) =>
          p.id === paper.id ? { ...p, views: (p.views || 0) + 1 } : p
        )
      );
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  // Filter papers based on searchTerm (searching in title and subject)
  const filteredPapersList = searchTerm
    ? papers.filter(
        (paper) =>
          paper.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (paper.subject &&
            paper.subject.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    : papers;

  // Group papers by grade and subject
  const groupPapers = (papers) => {
    const grouped = {};
    papers.forEach((paper) => {
      const grade = paper.grade;
      const subject = paper.subject || "all";
      const type = paper.type || "";

      if (!grouped[grade]) {
        grouped[grade] = { type: type, subjects: {} };
      }
      if (!grouped[grade].subjects[subject]) {
        grouped[grade].subjects[subject] = [];
      }
      grouped[grade].subjects[subject].push(paper);
    });

    // Sort each group by month and year in descending order (new to old)
    for (const grade in grouped) {
      for (const subject in grouped[grade].subjects) {
        grouped[grade].subjects[subject].sort((a, b) => {
          const dateA = new Date(
            a.year,
            new Date(a.month + " 1, 2024").getMonth()
          );
          const dateB = new Date(
            b.year,
            new Date(b.month + " 1, 2024").getMonth()
          );
          return dateB - dateA;
        });
      }
    }

    return grouped;
  };

  if (loading) {
    return <Loader />;
  }

  // Group only the filtered papers
  const groupedPapers = groupPapers(filteredPapersList);

  return (
    <>
      {/* Ad Modal inserted here */}
      <Modals />
      <section className="courses bg-[#F3F4F8] py-16">
        <div className="w-4/5 m-auto">
          {/* Header with title and search input */}
          <div className="flex justify-between items-center mb-16">
            <div className="heading">
              <h1 className="text-3xl font-semibold text-black">Free Practice</h1>
              <span className="text-sm mt-2 block">
                Work on your skills and improve your knowledge with our free papers.
              </span>
            </div>
            <div className="search flex items-center">
              <input
                type="text"
                placeholder="Search papers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="p-2 border border-gray-300 rounded"
              />
              <button
                className="ml-2 p-2 bg-blue-600 text-white rounded"
                onClick={() => {}}
              >
                Search
              </button>
            </div>
          </div>

          {/* Render grouped papers */}
          {Object.keys(groupedPapers).map((grade) => (
            <div key={grade} className="mt-6">
              <h2 className="text-2xl font-semibold text-indigo-500 mb-4">
                {grade.toUpperCase()}{" "}
                {groupedPapers[grade].type &&
                  `${groupedPapers[grade].type.toUpperCase()}`}
              </h2>
              {Object.keys(groupedPapers[grade].subjects).map((subject) => (
                <div key={subject} className="mt-4">
                  {subject !== "all" && (
                    <h3 className="text-xl font-semibold text-gray-600 mb-2">
                      {subject.toUpperCase()}
                    </h3>
                  )}
                  <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
                    {groupedPapers[grade].subjects[subject].map((item, index) => (
                      <motion.div
                        key={index}
                        className="box rounded-lg shadow-shadow1 bg-white"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                      >
                        <div className="images rounded-t-lg relative overflow-hidden h-40 w-full">
                          <img
                            src={getCoverImage(item) || LogoImg}
                            alt=""
                            className="rounded-t-lg object-cover w-full h-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                          />
                          <div className="categ flex gap-4 absolute top-0 m-3">
                            <span className="text-[14px] bg-rose-500 p-1 px-3 text-white rounded-[5px] shadow-md">
                              {item.subject}
                            </span>
                            <span className="text-[14px] bg-green-500 p-1 px-3 text-white rounded-[5px] shadow-md">
                              {item.month} {item.year}
                            </span>
                          </div>
                        </div>
                        <div className="text p-3">
                          <h3 className="text-black my-4 font-medium h-10">
                            {item.title}
                          </h3>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-200 p-3">
                          <div className="flex flex-col">
                            {item.referenceLink && (
                              <a
                                href={item.referenceLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[14px] hover:text-primary"
                              >
                                Free Answer
                              </a>
                            )}
                            <span className="text-[14px] text-gray-600 mt-1">
                              Views: {item.views || 0}
                            </span>
                          </div>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleViewPaper(item)}
                            className="text-[14px] flex items-center hover:text-primary"
                          >
                            View Paper <HiOutlineArrowNarrowRight />
                          </a>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Practice;
