import React, { useState, useEffect } from 'react';
import Courses from "../assets/images/courses.png";

function Modals() {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Automatically open the popup when the component mounts
        setIsOpen(true);
    }, []);

    // Function to close the modal
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            {isOpen && (
                <div onClick={closeModal} className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div onClick={(e) => e.stopPropagation()} className="relative bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto">
                        <button onClick={closeModal} className="absolute top-3 right-3 text-black bg-transparent rounded-full text-5xl p-2 leading-none">
                            &times;
                        </button>
                        <img
                            src={Courses}
                            alt="Courses"
                            className="w-full h-auto rounded-t-lg"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modals;
